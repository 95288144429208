import React, { Component } from "react"
import { Helmet } from "react-helmet";
import Layout from "../components/layout";


import "../css/style.css";
import "../css/animate.min.css";
import "../css/responsive.css";
import "../css/bootstrap.min.css";
import "../css/swiper-bundle.min.css";

import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from 'swiper';
// import ReactFullpage from '@fullpage/react-fullpage';

import { ReactTypeformEmbed } from 'react-typeform-embed';



Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);




class Privacypolicy  extends Component {
    componentDidMount(){
    
   
}


  constructor(props) {
        super(props);
        this.state = {
        }
    }



 

 

  render(){

    const post = this.props.data.allWordpressPage.nodes[0];
    const metatitle = post.acf.meta_title ;
    const metadescription = post.acf.meta_description; 
const focus_keyphrase = post.acf.focus_keyphrase; 
const canonical_url = `https://theworldgrad.com${this.props.path}`; 
    
   
    return(
        
      <div>
          <Layout>
          <Helmet>
                <title>{metatitle}</title>
          <meta name="description" content={metadescription} />
          {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
          <link rel="canonical" href={canonical_url} /> 
           {(() => {if(post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.dynamic_schema))}</script> )} })()}  
          

                </Helmet>
 {/*canonical_url*/}
                <div class="newStyle">  
		 <div className="container">
		 <ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
											<li class="breadcrumb-item">
												<a href="/">Home </a>
											</li>
											<li class="breadcrumb-item active"> {post.title}</li>
										</ol>
				</div>
				</div>


          <section class="countrie-banner-sec-hgttr-privacy">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="countrie-banner-inner-agrs">
                                
                                <div class="text-box-urds wow fadeInUp animated" data-wow-duration="1s" data-wow-delay=".1s">
                                    <h1>The WorldGrad | Privacy Policy</h1>
                                    <p>Privacy Policy</p>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>

            </section>


            <section class="scrds-dvtr-dnlr-ernd" id="privacy-policy-id-head">

            <div class="container">
             
            <div class="col-md-12 flt-lft">
                
                <p>Our main services relate to the provision of online programs and overseas admissions counselling to students where these students are either our direct consumer clients or are the end users ("End-Users") of our commercial clients (being tertiary institutions, other educational institutions, and corporations). Our services are primarily delivered through the internet and phone. </p>

                <p>You are advised to read this privacy policy before providing us with any personal information, before using the Service and prior to us providing any services to you. </p>

                <p>The WorldGrad is a trading name owned by LINC Education Services Pte. Ltd. which, including its subsidiaries and affiliates, together known as “The WorldGrad” is committed to comply with the Personal Data Protection Commission, Singapore (“PDPC”) and the Australian Privacy Principles ("APPs"). This policy is intended to explain clearly and in plain language some of the key processes and procedures that The WorldGrad has implemented to manage personal information, to protect the privacy of individuals and to comply with the Personal Data Protection Act, Singapore and Australian Privacy Act 1988 ("Act") and the Australian Privacy Principles.  </p>

                <p>In addition, as The WorldGrad may conduct business in the European Union ands India, The WorldGrad requires compliance with the General Data Protection Regulation (GDPR) on collection, processing, use and disposal of personal data and sensitive personal data as well as the Information Technology Act, 2000 (India).</p>

                <p>In its everyday business operations, The WorldGrad makes use of variety of data about identifiable individuals, including data about :</p>

                <ul>

                <li>1) Current, past and prospective employees</li>
                <li>2) Clients</li>
                <li>3) Users of its websites</li>
                <li>4) Subscribers</li>
                <li>5) Students</li>
                <li>6) Other Stakeholders</li>

                </ul>

                <p>In collecting and using this data, the organization is subject to a variety of legislation controlling how such activities may be carried out and the safeguards that must be put in place to protect it.</p>

                <p>This Privacy Policy describes how The WorldGrad collects, uses and discloses information, and what choices you have with respect to the information.</p>

                <p>By agreeing to accept the terms of this privacy policy and in providing personal information to us, you are taken to have consented or obtained the necessary consent to have personal information used or disclosed for the above purposes.</p>


                <h4>Services</h4>

                <p>The WorldGrad provides academic and non-academic support services for students engaged in tertiary education courses or courses leading to the commencement of tertiary education. The WorldGrad uses proprietary methodologies and systems in the provision of its services. All such products, applications, websites are collectively called “Services”.</p>

                <h4>Data Controller and Data Processor</h4>

                <p>We process two main types of personal data.</p>

                <p>1. <b>Student Data</b> - Personal data that forms part of data that is provided by our prospective students, enroled students and commercial clients and their students for processing.</p>

                <p> 2. <b>Other Data</b> - Personal data about universities, teachers, employees, visitors and other individuals that is collected and processed directly by us.</p>

                <p>As a Controller, we may collect personal information from students interested in our programs, students enroled in our programs and Educators which may also include sensitive information as well, by way of their enquiries, applications, resume / CV and other documentation.  The same may be shared with concerned Clients for enrolment of students and empanelment of educators, which is part of our business processes. The information so provided by students and educators will be used only for the purposes for which it is collected. </p>

                <p>We act as a Processor for students’ data to the extent provided by our Clients.  Sometimes, Clients may provide additional information like gender, religion etc. which we may not use in further processing. </p>

                <p>The WorldGrad may have video interviews with Students and Educators through external service providers, which may be stored with The WorldGrad or is stored and thereafter deleted by the service provider. </p>

                <h3>Information We Collect</h3>

                 <p>The WorldGrad will only collect information that is necessary and relevant to the proper undertaking of our business.</p>

                <h4>Student Data</h4>

                 <p>The types of personal information we collect may include name, email address (including personal email and institution email), mobile phone number, year of study, date of birth, postcode, study status, entity/institution name, type of entity/institution, nationality and contact preferences. We may collect personal information from our Clients or directly from individual students as End-Users. </p>

                <h4>University Data</h4>

                <p>University Data may be processed by us as a result of Client’s use of the Services when our Clients, or their End-Users, input or upload information into the Service. For example, Clients who use The WorldGrad may provide us data about themselves or their employees.</p>

                <p>This data includes name, email address, phone number, landline number and job title for university officials. We collect billing details for invoice purposes. We may also collect IP addresses of university officials to visualise how they are using dashboard reports and to improve upon design of the dashboard reports.</p>

                <h4>Sensitive Data</h4>

                <p>The WorldGrad does not call for or request any sensitive personal data. However, in the course of executing its business operations The WorldGrad may happen to receive and store part of sensitive data of students, if students on their own provide any personal information on voice calls or email or if the Clients share these with us. The call and communication records may be stored for quality monitoring and performance monitoring purposes as per the requirements of execution of services to our Clients.</p>

                <p>Clients may also provide gender details and other details like physical disability of students.  We do not currently process these details.</p>

                <h4>Educator Data</h4>

                <p>Educators send us their personal data while submitting their resume for empanelment with us. We process only the required data for reviewing the prospects and deciding on their empanelment. Their resume and evidence documentation with respect to claims in the resume are also shared with universities as a part of our contract for their approval while deploying an Educator for performance of services.</p>

                <h4>Other Data</h4>



                <p><b>Log Data </b> - Our servers automatically collect information when you access or use our applications and services. This data is recorded in log files. Examples of such data include IP address.</p>

                <p><b>Referrals </b> – Our enroled students and empanelled teachers may add you as a referral into our database for educational or professional opportunities.</p>

                <p><b>Contact Us Data and privacy@linceducation.com </b> – When you enquire about our products and services, we collect and store this data to communicate with you and respond to your enquiry.</p>

                <h4>Data from Other Sources</h4>

                <p>We collect data like IP addresses through cookies and pixels. The WorldGrad may use cookies for monitoring the users of services such as dashboards and information of other services of The WorldGrad. This may lead to getting information about IP addresses of users, which is not used and stored for any purposes. For more details about how we use these technologies, please see our Cookie Policy.</p>

                <h3>How We Use Your Data</h3>

                <p><b>Student Data</b></p>

                <p>Personal information or data provided by students directly to us will be used by The WorldGrad for the following purposes:</p>

                <ul>
                    <li> a) Providing, improving and developing our services,</li>
                    <li> b) Researching, designing and launching new products and services</li>
                    <li> c) Presenting content and information on our website in the most effective manner for your and your device use</li>
                    <li> d) Processing application forms or admissions processes</li>
                    <li> e) Complying to laws and regulations applicable to us in our regions of operation</li>
                    <li> f) For direct marketing purposes (see further details below)</li>
                    <li> g) Communicating with you and responding to your requests and</li>
                    <li> h) Purposes directly related to or incidental to the above</li>
                </ul>

                <p>We intend to use your personal data in direct marketing (i.e. offering or advertising our services by sending the relevant information directly to you). We require your consent specifically for this purpose and you may opt out anytime. For the purpose of this clause: </p>

                <ul>
                    <li>The personal data that be used for direct marketing are those that you provide to us or we collect from you </li>
                    <li>The information may be sent to you via phone, email, text messages, WhatsApp or video conferencing</li>
                </ul>

                <h4>University Data </h4>

                <p>University Data will be used by The WorldGrad in accordance with instructions, including any applicable terms in the Client Agreements to provide services and as required by applicable law.</p>

                <p>We use the data that we have about you to provide our services and support to you. In each case, The WorldGrad collects such information only insofar as is necessary or appropriate to fulfill the purpose of the interaction with our services.</p>

                 <p><b>Providing academic and related support to students </b>– The collected information from the students will be used only for the purposes of better services from Educators and reporting to Clients for addressing their concerns on performance, which is for furtherance of business operations in addition to quality monitoring.</p>

                <p>We may also collect additional information from End-Users in order to improve our services and performance. This may include voice recording of communications between students and Educators. These may be used for quality monitoring and resolving Client concerns about the service.</p>

                <p>We may also request for feedback from the End-Users on our Services and store that data to report and improve the quality of our Services.</p>

                <p>We may run analytics and profiling of the data collected to improve the efficiency and effectiveness of our Services.</p>

                <p><b>Communicating about the status of our services </b>– We may send our Clients service, technical and other administrative emails, messages and other types of communications. We may also contact our Clients to inform them about changes in our Services and our Services offerings. These communications are considered part of the Services and you may not opt out of them.</p>

                <p>If our Clients send us a request (for example, via a support email or via one of our feedback mechanisms), we respond to the request or help with their issues.</p>

                <p>For any other purpose as provided for in the Services Agreement between us and the Cleint, or as otherwise authorized by the Client;</p>

                <p>In accordance with or as may be required by law.</p>

                <h4>Educator Data</h4>

                <p><b>Recruitment, interviews and empanelment, student support and training </b>– We use prospective Educators’ data for review for empanelment decisions and decisions on deployment with Clients.</p>

                <h4>Any Other Data</h4>

                <p>We may also use personal information for other reasons, including:</p>

                <ul>
                    <li> 1)To prevent fraud and other criminal activities;</li>
                    <li> 2) To assist us to run our business and to improve our services and performance, including setting up our services, understanding the progress of, and services required by students, staff scheduling, staff training etc.;</li>
                    <li> 3) To provide customer and technical support;</li>
                    <li> 4) To comply with our legal obligations; and</li>
                    <li> 5) for marketing purposes.</li>
                </ul>

                <h4>Users under 16 years of age</h4>

                <p>If you are under the age of 16, you are not permitted to use the Sites and Services or to disclose Personal Information. If we learn we have collected or received Personal Information from a child under 16, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us.</p>

                <h3>Lawful Bases for Processing</h3>

                <p>We process personal data only when we have a lawful basis. Presently, we use the Performance of Contract (i.e. to deliver the services to our Clients) and consent as the lawful basis for processing. For certain processing, we may also use legitimate interests as provided under the General Data Protection Regulations.</p>

                <p>In some cases, we may also have a legal obligation to collect personal information or may otherwise need the personal information to protect your vital interests or those of another person.</p>

                <p>Where you have consented to a particular processing, you have a right to withdraw the consent at any time.</p>

                <h3>Unsolicited Personal Information</h3>

                <p>From time to time we may receive personal information that we have not requested or taken steps to come to know. In these circumstances, we will only hold onto such information where the information is necessary for our functions and activities. If we determine that the information received is not necessary for our functions and activities, then we will take steps to de-identify or destroy the information as soon as is practicable. Any determination as to whether or not the information is required to be retained by us will be made within a reasonable period after the information is received by us. Any information that has not been requested but is subsequently retained by us will be subject to the procedures and requirements set out in this Privacy Policy.</p>

                <h3>Data Retention Policy</h3>

                <p>We will retain personal information for as long as is needed to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements).</p>

                <p><b>University and Student Data</b> - We retain University and Student Data for as long as our Clients have an active services agreement with us. We may also retain University and Student Data for an extended period under applicable statutory laws, our services agreement or as per our Retention Policy. When you inform us about the deletion of your data, we delete all personal information about you.</p>

                <p><b> Educator Data and Other Data</b> - We retain your information for as long as necessary for the purposes that we have described in this Privacy Policy.</p>

                <h3>Your Rights</h3>

                <p>You can request to access, update or correct your personal information. You also have the right to object to direct marketing.</p>

                <p>You may have additional rights pursuant to your local law applicable to the processing. For example, if the processing of your personal information is subject to the EU General Data Protection Regulation ("GDPR"), and your personal information is processed based on legitimate interests, you have the right to object to the processing on grounds relating to your specific situation. Under GDPR you may also have the right to request to have your personal information deleted or restricted and ask for portability of your personal information.</p>

                <h4>Your Rights to Control Data</h4>

                <p>We strive to give you ways to access, update/modify your data quickly or to delete it unless we have to keep that information for legal purposes. These rights can be exercised by contacting us with your specific request.</p>

                <p><b>Change or Correct Data</b>: You can ask us to change, update or fix your data in certain cases, particularly if it’s inaccurate.</p>

                <p><b>Delete Data</b>:  You can ask us to erase or delete all or some of your personal data (e.g. if it is no longer necessary to provide Services to you).</p>

                <p><b>Object to, or Limit or Restrict, Use of Data</b>:  You can ask us to stop using all or some of your personal data (e.g. if we have no legal right to keep using it) or to limit our use of it (e.g. if your personal data is inaccurate or unlawfully held).</p>

                <p><b>Right to Access and/or Take Your Data</b>:  You can ask us for a copy of your personal data in machine readable form.</p>

                <p>Upon receiving your request, we will process the request within 30 days. Some information that is necessary for statutory obligations such as records of payment processing, invoicing data will be retained as necessary.</p>

                <h3>Information Shared with Others</h3>

                <h4>Recipients of the data</h4>

                <p>The data will be shared with other processors in order to provide the Services. The following categories of recipients will most likely receive the data in order for us to provide the Services</p>

                <ul>
                <li> 1) Third Party Education Providers such as colleges and universities</li>
                <li> 2) Third Party Data Center Services such as AWS, Google</li>
                <li> 3) Third Party Data Collection Services such as Typeform</li>
                <li> 4) Business Analytics services such as Tableau</li>
                <li> 5) Third Party SMTP Services such as Mailchimp</li>
                </ul>

                <p><b>To Comply with Laws</b> If we receive a request for information, we may disclose if we reasonably believe disclosure is in accordance with or required by any applicable law, regulation or legal process.</p>

                <p>The WorldGrad will also share information regarding the usage of its services by its End-Users with its Clients by way of periodic reports. Where Clients (such as universities, tertiary institutions, etc.) have entered into a contract to use our services, those Clients will have access to, and knowledge of the usage patterns of the Services by their students. Such usage patterns will include the content of student interactions and any comments left in feedback surveys.</p>

                <h4>Cross-Border Data Transfers </h4>

                <p>Since we are an international company, data may be processed outside the country of its origin, unless required by the local laws identified in the corresponding services agreement with our Client.</p>

                <p>The data may be processed within Third Party Data Centres outside of the country and in our offices at Singapore, Australia and India. Some countries where we process data may not have as protective laws as your own country and there are risks associated with such transfer.</p>

                <p>For our EU Clients, The WorldGrad offers European Union Model Clauses, also known as Standard Contractual Clauses, to meet the adequacy and security for the international transfers of Client Data. These clauses are contractual commitments between parties transferring personal data (for example, between The WorldGrad and its Clients, suppliers or data processors outside the EU), binding them to protect the privacy and security of the data.</p>

                <h3>Security Measures to Protect Data</h3>

                <h4>Security Measures</h4>

                <p>We maintain reasonable and appropriate security measures to protect Client Data from loss, misuse, and unauthorized access, disclosure, alteration, and destruction.</p>

                <p>Examples of security measures include physical access controls, HTTPS, restricted access to data, monitoring for threats and vulnerabilities etc.</p>

                <h4>Protection of personal information</h4>

                <p><a href="https://theworldgrad.com/" rel="noopener noreferrer">The WorldGrad</a>  takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally identifying and personally identifying information. </p>

                <h3>Links to Other Websites</h3>

                <p>The WorldGrad websites may contain links to websites that are owned or operated by other parties. You should make your own enquiries as to the privacy policies of these parties. We are not responsible for information on, or the privacy practices of, such websites.</p>

                <h3>Contact Information</h3>

                <p>Please find below our contact details. Please do not hesitate to contact us in relation to any privacy-related concerns and we will use our best endeavours to address any such concerns thoroughly and in a timely manner.</p>

                <p>If it is practical to do so, you can contact us without identifying yourself. However, if you choose not to identify yourself, it may be more difficult for us to assist you with your enquiry. This will depend on the nature of your enquiry.</p>

                <p>You may contact us at our mailing address below or via email:</p>

                <p>LINC Education Services Pte. Ltd.<br/>151, Chin Swee Road, #07-12 Manhattan House<br/>Singapore 169876</p>

                <p>Email: privacy@linceducation.com</p>

                <p>Mr. Abhinav Mital will be considered as Privacy Officer of the company. He can be contacted at privacy@linceducation.com.</p>

                <p>If you are a resident of the European Economic Area and we maintain your Personal Data within the scope of the General Data Protection Regulation (GDPR), you have additional rights.  If you are not satisfied with the resolution, you can also lodge a complaint with the Supervisory Authority in the country of your residence. Supervisory Authority for GDPR compliance will be Information Commissioner’s Office (ICO).  (ico.org.uk/)</p>

                <p>Our local representative in the UK is Ms. Mehak Gupta. She can be reached at localrep@linceducation.com.</p>

                <h4>Privacy Policy change</h4>

                <p>The WorldGrad encourages visitors to frequently check this page for any changes to its Privacy Policy. We will notify you of material changes in advance by email or by notice when you log in to the Sites and Services or both. You confirm that your continued use of our services after any change in this Privacy Policy will constitute your acceptance of such changes and agree to be subject to the revised privacy policy.</p>

                <p>You may determine if any such revision has taken place by referring to the date on which this Policy was last updated. </p>

            </div>

            </div>
            
            </section>


            </Layout>
            </div>




            
            
    )

    
  }
}



export default Privacypolicy;

export const PrivacypolicyQuery = graphql`
query PrivacypolicyQuery {
    allWordpressPage(filter: {wordpress_id: {in: 3}}) {
      nodes { 
        title
        wordpress_id
        acf {
            conference_link
meta_title
meta_description
focus_keyphrase
canonical_url
dynamic_schema
        }
    }
    }
}`
